import React, {Fragment} from 'react'
import { Row, Col } from 'react-flexbox-grid'
import Layout from '../dfa-theme/layout/hcp'
import WistiaVideo from '../wistia-video'


const metaTags = {
  description: 'Access videos for SUPPRELIN® LA (histrelin acetate) subcutaneous implant.',
  keywords: 'Homepage',
  title: 'Video Library | SUPPRELIN® LA (histrelin acetate)'
}

const externalLinksMetaTags = {
  category: 'External Link',
  action: 'Click',
  label: 'Deerfield Homepage',
}


class HCPProcedureVideoPage extends React.Component {

  render() {


    return (
        <Fragment>
          <Row>
            <Col xs={12}>
              <h1>SUPPRELIN<sup>&reg;</sup> LA Procedure Video</h1>
            </Col>
          </Row>
          <Row center={'sm'}>
              <Col col={8}>
                <WistiaVideo wistiaID="y08pmoo239" />
              </Col>
          </Row>
        </Fragment>
      )
  }
}

export default HCPProcedureVideoPage
