import React, {Fragment} from "react"
import { Row, Col } from 'react-flexbox-grid'
import { navigate } from "gatsby"
import { handleLogin, isLoggedIn } from "../../services/auth"
import { Button, FormGroup, InputGroup, ButtonGroup, Checkbox } from "@blueprintjs/core";

class Login extends React.Component {
  state = {
    username: ``,
    password: ``,
  }

  handleUpdate = event => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  handleSubmit = event => {
    event.preventDefault()
    handleLogin(this.state).then( (user)=> {
        console.log(user);
        if (!user) {
            console.log('have promise user')
            navigate(`/hcp/procedure-video/view`)
        }
    })
  }

  render() {
      
    if (isLoggedIn()) {
        console.log('navigating to hcp')
        navigate(`/hcp/procedure-video/view`)
    }

    return (
      <>
        <Row>
            <Col xs={10} md={6} >
            {/* <h1>Log in</h1> */}
            <form
            method="post"
            onSubmit={event => {
                this.handleSubmit(event)
                navigate(`/hcp/procedure-video/view`)
            }}
            >
            <FormGroup
            helperText="Please enter your username"
            label="Username"
            labelFor="username"
            labelInfo="(required)"
            >
            <InputGroup intent={(false && 'danger') || 'primary'} large type="text" name="username" onChange={this.handleUpdate} />
            </FormGroup>

            <FormGroup
            helperText="Please enter your password"
            label="Password"
            labelFor="password"
            labelInfo="(required)"
            >
            <InputGroup intent={(false && 'danger') || 'primary'} large onChange={this.handleUpdate} name="password" type="password" />
            </FormGroup>

            <input type="submit" value="Log In" />
            </form>  
            </Col>
        </Row>
      </>
    )
  }
}

export default Login